import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom"; // import useParams
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  List,
  CssBaseline,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Tooltip,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import useMediaQuery from "@mui/material/useMediaQuery";
import Tagit from "../assets/img/tagit.png";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import MenuItemsGenerator from "../models/Index";
import { useThemeContext } from "../components/theme/ThemeContext";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  "&::-webkit-scrollbar": {
    display: "none",
  },
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Sidebar = ({ open, setOpen }) => {
  const { theme } = useThemeContext();
  const Theme = useTheme();
  const menuItems = MenuItemsGenerator();
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { loanUid, report } = useParams();
  const isLargeScreen = useMediaQuery("(min-width:1200px)");

  useEffect(() => {
    menuItems.forEach((item) => {
      if (item.submenu) {
        item.submenu.forEach((subItem) => {
          if (location.pathname === subItem.path) {
            setOpenSubmenu(item.text);
          }
        });
      }
    });
  }, [location]);

  const handleSettingOpen = (submenu) => {
    setOpenSubmenu(openSubmenu === submenu ? null : submenu);
  };

  const handleMenuClick = (path) => {
    navigate(path);
    if (!isLargeScreen) {
      setOpen(false);
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        open={open}
        PaperProps={{
          sx: {
            backgroundColor:
              theme === "dark"
                ? Theme.palette.dark.light
                : Theme.palette.barcolor.main,
            "&::-webkit-scrollbar": {
              display: "none",
            },
          },
        }}
      >
        <DrawerHeader />
        <Divider />
        <List>
          {menuItems.map((item, index) => (
            <React.Fragment key={index}>
              <ListItem
                disablePadding
                onClick={() =>
                  item.submenu?.length > 0 && !item.path
                    ? handleSettingOpen(item.text)
                    : handleMenuClick(item.path)
                }
                style={
                  location.pathname === item.path
                    ? {
                        backgroundColor: theme === "dark" ? "#1e1f1f" : "",
                        borderLeft: "3px solid #a71307",
                        marginRight: "10px",
                        color:
                          theme === "dark"
                            ? Theme.palette.primary.main
                            : Theme.palette.primary.main,
                        fontWeight: "600 !important",
                      }
                    : {}
                }
              >
                <ListItemButton sx={{ px: 2.5 }}>
                  <Tooltip
                    title={!open ? item.text : ""}
                    placement="right"
                    slotProps={{
                      popper: {
                        modifiers: [
                          { name: "offset", options: { offset: [0, -30] } },
                        ],
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        color:
                          location.pathname === item.path ? "#a71307" : "gray",
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={item.text}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                  {item.submenu?.length > 0 &&
                    (openSubmenu === item.text ? (
                      <ExpandLess
                        sx={{ opacity: open ? 1 : 0, color: "primary.main" }}
                      />
                    ) : (
                      <ExpandMore
                        sx={{ opacity: open ? 1 : 0, color: "primary.main" }}
                      />
                    ))}
                </ListItemButton>
              </ListItem>
              {item.submenu && (
                <Collapse
                  in={openSubmenu === item.text}
                  timeout="auto"
                  unmountOnExit
                >
                  {item.submenu.map((subItem, subIndex) => (
                    <ListItem
                      key={subIndex}
                      component="div"
                      disablePadding
                      onClick={() => handleMenuClick(subItem.path)}
                      style={
                        location.pathname === subItem.path ||
                        (subItem.text === "Ledger Report" && report) ||
                        (subItem.text === "View" && loanUid)
                          ? {
                              backgroundColor:
                                theme === "dark" ? "#1e1f1f" : "",
                              borderLeft: "3px solid #a71307",
                              marginRight: "10px",
                              color:
                                theme === "dark"
                                  ? Theme.palette.primary.main
                                  : Theme.palette.primary.main,
                              fontWeight: "600 !important",
                            }
                          : {}
                      }
                    >
                      <ListItemButton sx={{ px: 2.5 }}>
                        <Tooltip
                          title={!open ? subItem.text : ""}
                          placement="right"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: { offset: [0, -30] },
                                },
                              ],
                            },
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              color:
                                location.pathname === subItem.path ||
                                (subItem.text === "Ledger Report" && report) ||
                                (subItem.text === "View" && loanUid)
                                  ? "#a71307"
                                  : "gray",
                              paddingLeft: open ? "10px" : "",
                            }}
                          >
                            {subItem.icon}
                          </ListItemIcon>
                        </Tooltip>
                        <ListItemText
                          primary={subItem.text}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </Collapse>
              )}
            </React.Fragment>
          ))}
        </List>
        <Divider />
        <Box
          sx={{ mt: 7, display: open ? "block" : "none", cursor: "pointer" }}
        >
          <ListItemButton sx={{ px: 2.5 }}>
            <a href="https://tagit.store/" target="blank">
              <img src={Tagit} width={180} alt="Tagit" />
            </a>
          </ListItemButton>
        </Box>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
