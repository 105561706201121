import React, { useState, useEffect } from "react";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import CashbookIcon from "@mui/icons-material/CardMembership";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import TuneIcon from "@mui/icons-material/Tune";
import CardMembershipIcon from '@mui/icons-material/BarChart';
import { useDispatch } from "react-redux";
import commonApi from "../services/common";
import { showLoader, hideLoader } from "../redux/loader/action";
import { showSnackbar } from "../redux/snackbar/action";
import {setEncryptedCookie, getDecryptedCookie, removeCookie} from "../utils"

// Define an icon mapping for dynamic menu rendering
const iconMap = {
  Dashboard: <DashboardOutlinedIcon />,
  டாஷ்போர்டு: <DashboardOutlinedIcon />,
  Customer: <GroupOutlinedIcon />,
  வாடிக்கையாளர்: <GroupOutlinedIcon />,
  Loan: <LocalAtmOutlinedIcon />,
  கடன்: <LocalAtmOutlinedIcon />,
  Accounts: <AccountBalanceOutlinedIcon />,
  கணக்குகள்: <AccountBalanceOutlinedIcon />,
  Settings: <SettingsOutlinedIcon />,
  அமைப்புகள்: <SettingsOutlinedIcon />,
  Notice: <DescriptionOutlinedIcon />,
  கவனிக்கவும்: <DescriptionOutlinedIcon />,
  Reports: <DescriptionOutlinedIcon />,
  அறிக்கைகள்: <DescriptionOutlinedIcon />,
  Open: <ImportContactsIcon />,
  "புதிய கடன்": <ImportContactsIcon />,
  "கடன் மூடல்": <ContactsOutlinedIcon />,
  Close: <ContactsOutlinedIcon />,
  View: <RemoveRedEyeIcon />,
  காண்க: <RemoveRedEyeIcon />,
  Daybook: <EditCalendarIcon />,
  "நாள் புத்தகம்": <EditCalendarIcon />,
  "Audit Cashbook": <CashbookIcon />,
  "தணிக்கை புத்தகம்": <CashbookIcon />,
  "Ledger Report": <CardMembershipIcon />,
  "லெட்ஜர் அறிக்கை": <CardMembershipIcon />,
  "Yearly Report": <QueryStatsIcon />,
  "ஆண்டு அறிக்கை": <QueryStatsIcon />,
  Configuration: <TuneIcon />,
  கட்டமைப்பு: <TuneIcon />,
};

const MenuItemsGenerator = () => {
  const dispatch = useDispatch();
  const [menuItems, setMenuItems] = useState([]);

  const ssoUserId = getDecryptedCookie("ssoUid");
  const businessId = getDecryptedCookie("bi");

  const getMenuList = () => {
    dispatch(showLoader());
    commonApi
      .getMenu(ssoUserId, businessId)
      .then((res) => {
        dispatch(hideLoader());
        if (res?.data?.status) {
          const menus = res.data.data;
          // Iterate through menus and submenus to set session storage for is_write === false or null
          menus.forEach((menu) => {
            const menuKey = menu.link.split("/").pop();
            if (menu.is_write === false || menu.is_write === null) {
              if (menuKey) {
                localStorage.setItem(menuKey, "false");
              }
            } else if (menu.is_write === true && menuKey) {
              localStorage.removeItem(menuKey);
            }
  
            menu.submenus.forEach((submenu) => {
              const submenuKey = submenu.link.split("/").pop();
              if (submenu.is_write === false || submenu.is_write === null) {
                if (submenuKey) {
                  localStorage.setItem(submenuKey, "false");
                }
              } else if (submenu.is_write === true && submenuKey) {
                localStorage.removeItem(submenuKey);
              }
            });
          });
  
          // Filter menus based on is_read and is_write
          const filteredMenus = menus
            .filter((menu) => menu.is_write !== null)
            .map((menu) => ({
              ...menu,
              submenus: menu.submenus.filter(
                (submenu) =>
                  submenu.is_write !== null
              ),
            }));
  
          // Sort menus and submenus
          const sortedMenus = filteredMenus.sort((a, b) => a.menu_id - b.menu_id);

          sortedMenus.forEach((menu) => {
            menu.submenus.sort((a, b) => a.menu_id - b.menu_id);
          });
  
          // Format menu items for rendering
          const formattedMenuItems = sortedMenus.map((menu) => ({
            text: menu.name,
            icon: iconMap[menu.name] || null,
            path: menu.link.trim() || null,
            submenu: menu.submenus.map((submenu) => ({
              text: submenu.name,
              icon: iconMap[submenu.name] || null,
              path: submenu.link.trim(),
            })),
          }));
  
          setMenuItems(formattedMenuItems);
        }
      })
      .catch((err) => {
        dispatch(hideLoader());
        // dispatch(
        //   showSnackbar({
        //     message: err?.response?.data?.message || "Error fetching menu",
        //     autoHideDuration: 3000,
        //     anchorOrigin: { vertical: "top", horizontal: "right" },
        //     variant: "error",
        //   })
        // );
      });
  };
  

  useEffect(() => {
    getMenuList();
  }, []);

  return menuItems;
};

export default MenuItemsGenerator;
