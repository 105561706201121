import { createTheme } from "@mui/material/styles";

// const theme = createTheme({
//     palette: {
//         primary: {
//             main: '#a51e22',
//             light: '#fceaee',
//         },
//         secondary:{
//             main: '#4ab5b2',
//             light:'#b1dede',
//             dark: '#006861',
//           },
//         decor:{
//             main: '#e4448d',
//             light:'#ec91bd',
//             dark: '#e32278',
//           },
//         dark:{
//             light:'gray',
//             main: '#3c3c47',
//           },
//     },
//     typography: {
//       fontFamily:'"Quicksand", "Nunito", sans-serif, Pavanam',
//     },
    
// })


// export default theme;

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
        main: '#a71307',
        light: '#fceaee',
    },
    barcolor:{
      main: '#ffffff',
    },
    secondary:{
        main: '#4ab5b2',
        light:'#b1dede',
        dark: '#006861',
      },
    decor:{
        main: '#e4448d',
        light:'#ec91bd',
        dark: '#e32278',
      },
    dark:{
        light:'#d1d1d1',
        main: '#3c3c47',
      },
    gradient: {
      main: "linear-gradient(90deg, rgba(167,7,7,1) 0%, rgba(240,110,110,1) 75%)",
    },
  },
  typography: {
    fontFamily:'"Quicksand", "Nunito", sans-serif, Pavanam',
    fontWeightLight:400,
    fontWeightRegular:500,
    fontWeightMedium:600,
    fontWeightBold:700,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          height: "100%",
          // background: "linear-gradient(45deg, #ff4b2b 30%, #ff416c 90%)",
          // color: "#fff",
          borderRadius: "5px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          textTransform: "uppercase",
          fontWeight: "bold",
          "&:hover": {
            // background: "linear-gradient(45deg, #ff416c 30%, #ff4b2b 90%)",
            boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.2)",
          },
        },
      },
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#1f2020', // Background color
      paper: '#1f2020', // Slightly lighter for cards and surfaces
    },
    primary: {
      main: '#ad281e', // Darker shade of the primary color
      light: '#2a2b2b', // A slightly lighter version for a highlight effect
    },
    barcolor:{
      main: '#2a2b2b',
    },
    secondary: {
      main: '#2b8b88', // Darker shade of the secondary color
      light: '#3fa3a0', // A slightly lighter version for a highlight effect
      dark: '#00534d', // A darker version for deeper shadows
    },
    decor: {
      main: '#bf3a75', // Darker shade of the decor color
      light: '#d56097', // A slightly lighter version for a highlight effect
      dark: '#991e59', // A darker version for deeper shadows
    },
    dark: {
      light: '#2c3134', // Lighter gray for contrasts
      main: '#1f2020', 
      // Main dark color for the theme
    },
    gradient: {
      // main: "linear-gradient(90deg, rgba(255,81,66,1) 0%, rgba(42,43,43,1) 75%)",
      main: "linear-gradient(90deg, rgba(167,7,7,1) 0%, rgba(240,110,110,1) 75%)",
    },
  },
  typography: {
    fontFamily:'"Quicksand", "Nunito", sans-serif, Pavanam',
    fontWeightLight:400,
    fontWeightRegular:500,
    fontWeightMedium:600,
    fontWeightBold:700,
  },
  // components: {
  //   MuiButton: {
  //     styleOverrides: {
  //       contained: {
  //         height: "100%",
  //         background: "linear-gradient(45deg, #ff4b2b 30%, #ff416c 90%)",
  //         color: "#fff",
  //         borderRadius: "5px",
  //         boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
  //         textTransform: "uppercase",
  //         fontWeight: "bold",
  //         "&:hover": {
  //           background: "linear-gradient(45deg, #ff416c 30%, #ff4b2b 90%)",
  //           boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.3)",
  //         },
  //       },
  //     },
  //   },
  // },
});
