const BASE_URL = process.env.REACT_APP_BASE_URL;
const TAG_BASE_URL = process.env.REACT_APP_BASE_URL;

const AUTH_BASE_URL = `${TAG_BASE_URL}/tagitstore_auth`;
const USERS_BASE_URL = `${TAG_BASE_URL}/tagitstore_users`;

const API_BASE_URL = `${BASE_URL}/pba75001py_public`;
const FILE_BASE_URL = `${BASE_URL}/pba75001py_files/files`;

const MASTER_DATA = `${BASE_URL}/pba75001py_public/master`;
const PRODUCT_CUSTOMER = `${BASE_URL}/pba75001py_customers`;
const PRODUCT_CONFIG = `${BASE_URL}/pba75001py_settings_config`;
const BUSSNESS_LICENCE = `${BASE_URL}/pba75001py_store_config/pba_business_license_config`;
const DAYBOOK =
  "https://nx7qawagcexob5f3agbb5itkey0yeuyp.lambda-url.ap-south-1.on.aws";

export const URL = {
  BOC: process.env.REACT_APP_BOC_BASE_URL,
};

const PDF_URL = process.env.REACT_APP_PDF_BASE_URL;

export const API_URL = {
  LOGIN: `${AUTH_BASE_URL}/product/user/login`,
  REFRESH_TOKEN: `${AUTH_BASE_URL}/refreshToken/boc`,
  GET_BUSINESS_ID: `${USERS_BASE_URL}/users/product/business`,
  BUSINESS_LOGIN: `${AUTH_BASE_URL}/product/user/login/change`,
  VALIDATE_TOKEN: `${AUTH_BASE_URL}/product/user/login/verifytoken`,
  FORGOT: `${AUTH_BASE_URL}/forgotpassword/product`,
  RESET_PASSWORD: `${AUTH_BASE_URL}/forgotpassword/product/verify`,
  CHANGE_PASSWORD: `${AUTH_BASE_URL}/resetpassword/boc`,
  LOGOUT_USER: `${AUTH_BASE_URL}/user/logout/boc`,
  GET_MENUS: `${BASE_URL}/pba75001py_users/user_role/services/get-menus-details`,
  CREATE_TENT_INTEREST: `${PRODUCT_CONFIG}/configuration/services/configuration/create-tent-interest`,
  GET_TENT_INTEREST: `${PRODUCT_CONFIG}/configuration/services/configuration/get-tent-interest`,
  CREATE_NOTICE_CHARGES: `${PRODUCT_CONFIG}/configuration/services/configuration/create-notice-charges`,
  GET_NOTICE_CHARGES: `${PRODUCT_CONFIG}/configuration/services/configuration/get-notice-charges`,
  CREATE_APPRAISER_CHARGES: `${PRODUCT_CONFIG}/configuration/services/configuration/create-appraiser-charges`,
  GET_APPRAISER_CHARGES: `${PRODUCT_CONFIG}/configuration/services/configuration/get-appraiser-charges`,
  CREATE_LENDING_LIMIT: `${PRODUCT_CONFIG}/configuration/services/configuration/create-lending-limit`,
  GET_LENDING_LIMIT: `${PRODUCT_CONFIG}/configuration/services/configuration/getall-lending-limit`,
  CREATE_INTEREST_SLAB: `${PRODUCT_CONFIG}/configuration/services/configuration/create-tent-interest-slab`,
  GET_INTEREST_SLAB: `${PRODUCT_CONFIG}/configuration/services/configuration/getall-tent-intrest-slab`,
  FILE_UPLOAD: `${FILE_BASE_URL}/services/files/file-upload`,
  GET_FILE: `${BASE_URL}/pba75001py_files/files/services/files/get-files`,
  COUNTRY_CODE: `${API_BASE_URL}/master/services/master/getall-countries`,
  SELECT_DROPDOWN: `${API_BASE_URL}/master/services/master/getall-tent-lookup`,
  CUSTOMER_CREATE: `${PRODUCT_CUSTOMER}/customer/services/customer/create-customer`,
  GET_CUSTOMER: `${PRODUCT_CUSTOMER}/customer/services/customer/get-customers-list`,
  EDIT_CUSTOMER: `${PRODUCT_CUSTOMER}/customer/services/customer/update-customer`,
  CREATE_DROPDOWN: `${API_BASE_URL}/master/services/master/create-lookup`,
  // CUSTOMER_EXPORT: `${BASE_URL}/services/customer/customer-export`,
  CUSTOMER_AREA_SEARCH: `${PRODUCT_CUSTOMER}/customer/services/customer/get-specific-address`,
  CUSTOMER_AREA_DROPDOWN: `${MASTER_DATA}/services/master/getall-address`,
  CREATE_LOAN: `${PRODUCT_CONFIG}/configuration/services/loan/post-loan`,
  LOAN_PRINT: `${PDF_URL}/pba75001py_pdfexport/pba75001py_daybook/services/daybook/loan-print`,
  GET_ACTIVE_LOAN: `${PRODUCT_CONFIG}/loan-close/services/particular-loan-detail`,
  GET_LOAN_APPRAISER_CHARGES: `${PRODUCT_CONFIG}/configuration/services/loan/appraiser_charges`,
  GET_LOAN_INTEREST_CHARGES: `${PRODUCT_CONFIG}/configuration/services/loan/tent_interest_slab`,
  GET_LOAN_LENDING_CHARGES: `${PRODUCT_CONFIG}/configuration/services/loan/tent_lending_limit_charges`,
  VALIDATE_LOAN_DATE: `${PRODUCT_CONFIG}/configuration/services/loan/default-configuration`,
  GET_LICENCE: `${BUSSNESS_LICENCE}/services/get-all-license-detail`,
  LOAN_OPEN_SEARCH: `${PRODUCT_CONFIG}/loan-close/services/get-customer-search`,
  LOAN_SEARCH: `${PRODUCT_CONFIG}/loan-close/services/get-unique-loan-customer_search`,
  GET_LOAN_CUSTOMER: `${PRODUCT_CONFIG}/loan-close/services/get-customer-active-loans`,
  RUN_INTEREST: `${PRODUCT_CONFIG}/configuration/services/loan/run_interest`,
  CLOSE_LOAN: `${PRODUCT_CONFIG}/loan-close/services/loan-close`,
  GET_LOAN_VIEW: `${PRODUCT_CONFIG}/configuration/services/loan/loan_view_details`,
  GET_LOAN_ITEM: `${PRODUCT_CONFIG}/loan-close/services/get-all-loan-item`,
  GET_LOAN_DROPDOWNS: `${PRODUCT_CONFIG}/loan-close/services/get-unique-loan-view-customer_search`,
  GET_PARTPAYMENT_HISTORY: `${PRODUCT_CONFIG}/loan-close/services/get-partial-payment-history`,
  UPDATE_LOAN: `${PRODUCT_CONFIG}/configuration/services/loan/post-update`,
  LOAN_CANCEL: `${PRODUCT_CONFIG}/configuration/services/loan/loan-cancel`,
  EXPORT_CUSTOMER: `${BASE_URL}/pba75001py_reports/reports/services/customer/customer-export`,
  EXPORT_LOAN: `${BASE_URL}/pba75001py_reports/reports/services/customer/loan-export`,
  CREATE_LEDGER: `${BASE_URL}/pba75001py_daybook/services/ledger/post-ledger`,
  UPDATE_LEDGER: `${BASE_URL}/pba75001py_daybook/services/ledger/update-ledger`,
  GETALL_LEDGER: `${BASE_URL}/pba75001py_daybook/services/ledger/get-all-ledger`,
  GETID_LEDGER: `${BASE_URL}/pba75001py_daybook/services/ledger/get-ledger`,
  DELETE_LEDGER: `${BASE_URL}/pba75001py_daybook/services/ledger/delete-ledger`,
  GET_FILTER: `${BASE_URL}/pba75001py_daybook/services/daybook/get-unique-ledger-search`,
  CREATE_DAYBOOK: `${BASE_URL}/pba75001py_daybook/services/daybook/post-daybook`,
  GETALL_DAYBOOK: `${BASE_URL}/pba75001py_daybook/services/daybook/get-all-daybook`,
  GETID_DAYBOOK: `${BASE_URL}/pba75001py_daybook/services/daybook/get-daybook`,
  UPDATE_DAYBOOK: `${BASE_URL}/pba75001py_daybook/services/daybook/update-daybook`,
  DELETE_DAYBOOK: `${BASE_URL}/pba75001py_daybook/services/daybook/delete-daybook`,
  BALANCE: `${BASE_URL}/pba75001py_daybook/services/daybook/get-balance`,
  GET_ALL_DAYBOOK: `${BASE_URL}/pba75001py_daybook/services/daybook/get-all-daybook`,
  GET_VIEW_BALANCE: `${BASE_URL}/pba75001py_daybook/services/daybook/get-balance`,
  CHECK_CONFIG: `${PRODUCT_CONFIG}/configuration/services/loan/check_business_status`,
  EXPORT_PDF_DAYBOOK: `${PDF_URL}/pba75001py_pdfexport/pba75001py_daybook/services/daybook/get-all-daybook`,
  GET_CLOSE_LOAN: `${BASE_URL}/pba75001py_daybook/services/audit_cashbook/get-close-loans`,
  GET_OPEN_LOAN: `${BASE_URL}/pba75001py_daybook/services/audit_cashbook/get-open-loans`,
  CREATE_CREDIT_DEBIT: `${BASE_URL}/pba75001py_daybook/services/audit_cashbook/post-audit-cashbook/`,
  GET_AUDIT_CASH_BOOK: `${BASE_URL}/pba75001py_daybook/services/audit_cashbook/get-all-audit-cashbook-detail`,
  DELETE_AUDIT_CASH_BOOK: `${BASE_URL}/pba75001py_daybook/services/audit_cashbook/delete-audit-cashboook`,
  GET_DAY_BOOK_DETAIL: `${BASE_URL}/pba75001py_daybook/services/daybook/get-all-daybook-details`,
  DELETE_AUDIT_DAYBOOK: `${BASE_URL}/pba75001py_daybook/services/daybook/delete-daybook`,
  DELETE_MULTIPLE_AUDIT_DAYBOOK: `${BASE_URL}/pba75001py_daybook/services/daybook/delete-multiple-daybook`,
  UPDATE_MULTIPLE_AUDIT_DAYBOOK: `${BASE_URL}/pba75001py_daybook/services/daybook/update-multiple-daybook`,
  GET_CONSOLIDATE_DETAIL: `${BASE_URL}/pba75001py_daybook/services/audit_cashbook/get-consolidate-transaction-details`,
  LEDGER_REPORT_CUSTOMER_SEARCH: `${PRODUCT_CONFIG}/loan-close/services/get-unique-customer_search`,
  LEDGER_EXPORT: `${BASE_URL}/pba75001py_reports/reports/services/customer/ledger-export`,
  GET_YEARLY_REPORTS: `${PRODUCT_CONFIG}/configuration/services/loan/yearly-reports`,
  LEDGER_PDF_EXPORT: `${PDF_URL}/pba75001py_pdfexport/pba75001py_daybook/services/daybook/ledger-pdf-export`,
  YEARLY_PDF_EXPORT: `${PDF_URL}/pba75001py_pdfexport/pba75001py_yearly_reports/services/reports/yearly-reports`,
  CHECK_DATE_CONFIG: `${PRODUCT_CONFIG}/configuration/services/loan/effective_date_status`,
};

export const ACTION_TYPES = {
  SHOW_SNACKBAR: "SHOW_SNACKBAR",
  HIDE_SNACKBAR: "HIDE_SNACKBAR",
  SHOW_LOADER: "SHOW_LOADER",
  HIDE_LOADER: "HIDE_LOADER",
  AUTH: "AUTH",
};

export const SECRET_KEY = "1F49B45BDBDE8D513DCBFF4ABB1E7";
