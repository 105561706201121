import React, { useEffect } from "react";
import Index from "./routes/index";
import { ThemeProvider } from "./components/theme/ThemeContext";
import Snackbar from "./components/snackbar/index";
import { Provider } from "react-redux";
import store from "./redux/store/index";
import Loader from "./components/loader/index";

function App() {
  useEffect(() => {
    if (window.location.hostname === "localhost") return;
    const disableContextMenu = (e) => e.preventDefault();
    document.addEventListener("contextmenu", disableContextMenu);

    return () => {
      document.removeEventListener("contextmenu", disableContextMenu);
    };
  }, []);

  useEffect(() => {
    if (window.location.hostname === "localhost") return;
    const disableDevTools = (e) => {
      if (e.key === "F12") {
        e.preventDefault();
      }
      if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.key === "I") {
        e.preventDefault();
      }
      if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.key === "C") {
        e.preventDefault();
      }
      if ((e.ctrlKey || e.metaKey) && e.key === "U") {
        e.preventDefault();
      }
    };
    window.addEventListener("keydown", disableDevTools);
    return () => {
      window.removeEventListener("keydown", disableDevTools);
    };
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider>
        <Index />
        <Snackbar />
        <Loader />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
