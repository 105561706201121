import axios from "axios";
import { API_URL } from "../../constants";

export default {
  login: (data) => {
    return axios.post(API_URL.LOGIN, data, {
      headers: {  "Content-Type": "application/json" },
      withCredentials: true,
    });
  },
  businessLogin: (businessId) => {
    return axios.post(`${API_URL.BUSINESS_LOGIN}/${businessId}`,{}, {
      headers: {  "Content-Type": "application/json" },
      withCredentials: true,
    });
  },
  forgot: (data) => {
    return axios.post(API_URL.FORGOT, data, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
  },
  resetPassword: (data) => {
    return axios.post(API_URL.RESET_PASSWORD, data, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
  },  
  changePassword: (data) => {
    return axios.post(API_URL.CHANGE_PASSWORD, data, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
  },  
  getBusinessId: (data) => {
    return axios.post(API_URL.GET_BUSINESS_ID, data, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
  },  
  refreshToken: (data) => {
    return axios.post(API_URL.REFRESH_TOKEN, data, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
  },  
  validateToken: (data) => {
    return axios.post(API_URL.VALIDATE_TOKEN, data, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
  },  
  logoutUser: (data) => {
    return axios.post(API_URL.LOGOUT_USER, data, {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
  },
};

